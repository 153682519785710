export class QueryBuilder {
  private readonly query: any = {};

  constructor() {
    // Calculate the starting index for records
    // const from = pageIndex * pageSize;

    // Initialize the query object
    this.query = {
      query: {
        bool: {
          must: [
            { term: { isCompetitor: false } }
          ],
          filter: [],
          should: [],
          must_not: []
        }
      },
      sort: [{ "_id" : {"order" : "asc"}}],
      size: 10000,
      search_after : []
    };
  }

  // Add a text filter to the query
  public addTextFilter(field: string, type: string, filter?: string): QueryBuilder {
    switch (type) {
      case 'contains':
        this.query.query.bool.must.push({ regexp: { [field]: `.*${filter}.*` } });
        break;
      case 'notContains':
        this.query.query.bool.must_not.push({ regexp: { [field]: `.*${filter}.*` } });
        break;
      case 'equals':
        this.query.query.bool.must.push({ term: { [field]: filter } });
        break;
      case 'notEqual':
        this.query.query.bool.must_not.push({ term: { [field]: filter } });
        break;
      case 'startsWith':
        this.query.query.bool.must.push({ wildcard: { [field]: `${filter}*` } });
        break;
      case 'endsWith':
        this.query.query.bool.must.push({ wildcard: { [field]: `*${filter}` } });
        break;
      case 'blank':
        this.query.query.bool.should.push({ bool: { must_not: { exists: { field } } } });
        this.query.query.bool.should.push({ term: { [field]: "" } });
        this.query.query.bool.minimum_should_match = 1;
        break;
      case 'notBlank':
        this.query.query.bool.must.push({ exists: { field } });
        this.query.query.bool.must_not.push({ term: { [field]: "" } });
        break;
      default:
        throw new Error(`Invalid text filter type: ${type}`);
    }

    return this;
  }

// Add a numeric filter to the query
  public addNumericFilter(field: string, type: string, filter: number | number[] = null): QueryBuilder {
    switch (type) {
      case 'equals':
        this.query.query.bool.must.push({ term: { [field]: filter } });
        break;
      case 'notEqual':
        this.query.query.bool.must_not.push({ term: { [field]: filter } });
        break;
      case 'lessThan':
        this.query.query.bool.filter.push({ range: { [field]: { lt: filter } } });
        break;
      case 'lessThanOrEqual':
        this.query.query.bool.filter.push({ range: { [field]: { lte: filter } } });
        break;
      case 'greaterThan':
        this.query.query.bool.filter.push({ range: { [field]: { gt: filter } } });
        break;
      case 'greaterThanOrEqual':
        this.query.query.bool.filter.push({ range: { [field]: { gte: filter } } });
        break;
      case 'inRange':
        if (!Array.isArray(filter) || filter.length != 2) {
          throw new Error("The 'inRange' filter requires an array of two elements");
        }
        this.query.query.bool.filter.push({ range: { [field]: { gte : filter[0], lte : filter[1]} } });
        break;
      case 'blank':
        this.query.query.bool.must_not.push({ exists: { field } });
        break;
      case 'notBlank':
        this.query.query.bool.must.push({ exists: { field } });
        this.query.query.bool.must_not.push({ term: { [field]: "" } });
        break;
      default:
        throw new Error(`Invalid numeric filter type: ${type}`);
    }

    return this;
  }


  // Add a sorting to the query
  public addSort(sort: string, colId: string): QueryBuilder {
    this.query.sort.push({ [colId]: { order: sort } });
    return this;
  }

  // Build and return the final Elasticsearch query
  public build(): any {
    console.debug("visops-list-view: build query", this.query);
    return this.query;
  }

  public queryGeolocationListViewWithSearchAfterQuery(searchAfterIndex: String, batchSize: number):any{
    searchAfterIndex === null ? "" : searchAfterIndex;
    this.query.size = batchSize ;
    this.query.search_after.push(searchAfterIndex);
    console.debug("visops-list-view: queryGeolocationListViewWithSearchAfterQuery query", JSON.stringify(this.query));
    
    return this.query;
  }

  public queryStoreAttributesWithSearchAfterQuery(searchAfterIndex: String, batchSize: number):any{
    searchAfterIndex === null ? "" : searchAfterIndex;
    this.query.query.bool = {};
    this.query.query = {match_all:{}};
    this.query.size = batchSize ;
    this.query.search_after.push(searchAfterIndex);
    console.debug("visops-list-view: queryStoreAttributesWithSearchAfterQuery query", JSON.stringify(this.query));
    
    return this.query;
  }
}
