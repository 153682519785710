import { useEffect, useMemo, useRef, useState } from "react";
import { ListViewEventData } from "../../utils/dataDefinitions/dataTypes";
import { SetCenter } from "../../utils/dataDefinitions/functionTypes";
import { fetchListViewDataFromElasticSearch, ListViewApiResponseObject } from "../../utils/elasticApiCalls";
import { type Row } from '@tanstack/react-table';
import "./ListViewDrawer.css";
import {
  getMRT_RowSelectionHandler,
  MaterialReactTable,
  MRT_ActionMenuItem,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMaterialReactTable,
  MRT_ToggleGlobalFilterButton,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_Header,
  MRT_Column,
  MRT_TableInstance,
  MRT_RowData,
} from "material-react-table";
import { useLocalStorage, useStorage } from "../hooks/useLocalStorage";
import Alert from '@mui/material/Alert';
import {
  AppRegistration,
  Insights,
  Storefront,
} from "@mui/icons-material";
import { AlertTitle, Autocomplete, Badge, Checkbox, Chip, Collapse, Divider, IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import csvDownload from "json-to-csv-export";
import { stringifyNestedObjects } from "../../utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import React from "react";

const DELIMITER = ',';
const EMPTY = '-Empty-';
const SELECTED = 'Selected';
const VISIBLE_COLUMNS = 'Visible Columns';
const ALL = 'All';
const FILTERED = 'Filtered';
const DISBALED = 'Disabled';
const STORE_EXPORT = ' Store - EXPORT_';

type Props = {
  initMapViewChangeDetail: ListViewEventData;
  setCenter: SetCenter;
  compRefClose?: () => {};
  compRef: any;
};

const containsAllMultipleTagFilterFn = <TData extends MRT_RowData>(
  row: Row<TData>,
  id: string,
  filterValue: number | string,
) => {
  let isValid = true;

  const tagColumnValues = row.getValue<number | string>(id).toString().toLowerCase().trim();
  const filterValueArray = filterValue.toString().toLowerCase().trim().split(DELIMITER);

  filterValueArray.forEach((tag) => {
    const checkEachTag = tagColumnValues.includes(tag);
    if (!checkEachTag) {
      isValid = false;
    }
  });
  return isValid;
}

const containsEitherMultipleTagFilterFn = <TData extends MRT_RowData>(
  row: Row<TData>,
  id: string,
  filterValue: number | string,
) => {
  let isValid = false;

  const tagColumnValues = row.getValue<number | string>(id).toString().toLowerCase().trim();
  const filterValueArray = filterValue.toString().toLowerCase().trim().split(DELIMITER);

  filterValueArray.forEach((tag) => {
    const checkEachTag = tagColumnValues.includes(tag);
    if (checkEachTag) {
      isValid = true;
    }
  });
  return isValid;
}

const multipleOrFn = <TData extends MRT_RowData>(
  row: Row<TData>,
  id: string,
  filterValue: number | string,
) =>
  filterValue.toString().toLowerCase().trim().split(' ').map(fl => fl.replace('"', '').replace('"', '').trim())
    .includes(row.getValue<number | string>(id).toString().toLowerCase().trim());

const multiSelectOrFilterFn = <TData extends MRT_RowData>(
  row: Row<TData>,
  id: string,
  filterValue: number | string,
) =>
  filterValue.toString().includes(EMPTY)
    ? !row.getValue<number | string>(id).toString().toLowerCase().trim()
    || filterValue.toString().toLowerCase().trim().split(DELIMITER)
      .includes(row.getValue<number | string>(id).toString().toLowerCase().trim())
    : filterValue.toString().toLowerCase().trim().split(DELIMITER)
      .includes(row.getValue<number | string>(id).toString().toLowerCase().trim());


const tagCell = ({ cell }) => {
  const tagChildRef = useRef(null);
  useEffect(() => {
    const child = tagChildRef.current;
    child.style.marginTop = child.offsetHeight - child.clientHeight - 1 + 'px';
  }, []);
  return (
    <div className="mrt-tag-cell-container">
      <div className="mrt-tag-cell" ref={tagChildRef}>
        {cell?.getValue()?.length > 0 &&
          cell?.getValue()?.toString().split(DELIMITER).map((tag, index) => (
            <span className={`mrt-tag ${cell.column.id}`} key={index}> <i className="fas fa-tag" aria-hidden="true" /> {tag.trim()}</span>
          ))
        }
      </div>
    </div>
  )
}

const renderFilterModeOptions = ({ column, onSelectFilterMode }) => {
  const filterOptionsList = [{
    divider: false,
    option: "contains",
    label: "Contains",
    symbol: "*"
  },
  {
    divider: false,
    option: "multipleOrFn",
    label: "Multiple",
    symbol: "* *"
  },
  {
    divider: false,
    option: "equals",
    label: "Equals",
    symbol: "="
  },
  {
    divider: false,
    option: "notEquals",
    label: "Not Equals",
    symbol: "≠"
  },
  {
    divider: true,
  },
  {
    divider: false,
    option: "empty",
    label: "Empty",
    symbol: "∅"
  },
  {
    divider: false,
    option: "notEmpty",
    label: "Not Empty",
    symbol: "!∅"
  },
  ];

  let filterOptionMenu = [];
  filterOptionsList.forEach((filterOption, index) => {
    if (filterOption.divider) {
      filterOptionMenu.push(<Divider key={index} />);
    }
    else {
      filterOptionMenu.push(
        <MenuItem
          key={index}
          onClick={() => onSelectFilterMode(filterOption.option)}
        >
          <div className="mrt-custom-filter-options">
            <div className="mrt-custom-filter-symbol-icon"> {filterOption.symbol}</div>
            {filterOption.label}
          </div>
        </MenuItem>)
    }
  })
  return filterOptionMenu;
};

//Column Properties
const columnDef: MRT_ColumnDef<any>[] = [
  { header: "Store ID", size: 120, accessorKey: "importcode", filterFn: 'contains', renderColumnFilterModeMenuItems: renderFilterModeOptions, columnFilterModeOptions: ['contains'] },
  { header: "Store Description", accessorKey: "displayname", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "Brand", size: 160, accessorKey: "brandname", Filter: multiAutoCompleteFilterComponent, filterFn: multiSelectOrFilterFn, enableFilterMatchHighlighting: false },
  { header: "Reg Price", size: 130, accessorKey: "regPrice", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "Reg Price ValidFrom", size: 190, accessorKey: "regPriceValidFrom", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "DSL Price", size: 130, accessorKey: "dslPrice", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "Dsl Price ValidFrom", size: 190, accessorKey: "dslPriceValidFrom", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "SEI ID", size: 120, accessorKey: "seiid", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "Market", accessorKey: "market", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "Zone", accessorKey: "zone", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "POS Type", size: 150, accessorKey: "postype", Filter: multiAutoCompleteFilterComponent, filterFn: multiSelectOrFilterFn, enableFilterMatchHighlighting: false },
  { header: "State", size: 130, accessorKey: "state", Filter: multiAutoCompleteFilterComponent, filterFn: multiSelectOrFilterFn, enableFilterMatchHighlighting: false },
  { header: "City", size: 130, accessorKey: "city", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "DMA", size: 130, accessorKey: "dma", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "DMA Code", size: 124, accessorKey: "dmaCode", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "Zipcode", size: 110, accessorKey: "zipCode", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "County", accessorKey: "county", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "Manager", accessorKey: "managerUserId", filterFn: 'contains', columnFilterModeOptions: ['contains', 'equals', 'notEquals', 'empty', 'notEmpty'] },
  { header: "Analyst", accessorKey: "analystUserId", Filter: multiAutoCompleteFilterComponent, filterFn: multiSelectOrFilterFn, enableFilterMatchHighlighting: false },
  { header: "Price Source", size: 150, accessorKey: "priceSource", Filter: multiAutoCompleteFilterComponent, filterFn: multiSelectOrFilterFn, enableFilterMatchHighlighting: false },
  { header: "Market Move Tag", size: 200, accessorKey: "marketMoveTag", Filter: multiAutoCompleteFilterComponent, filterFn: containsEitherMultipleTagFilterFn, enableFilterMatchHighlighting: false, Cell: tagCell },
  { header: "Strategy Test Tag", size: 200, accessorKey: "strategyTestTag", Filter: multiAutoCompleteFilterComponent, filterFn: containsEitherMultipleTagFilterFn, enableFilterMatchHighlighting: false, Cell: tagCell },
  { header: "Own Site Tag", size: 200, accessorKey: "ownSiteTag", Filter: multiAutoCompleteFilterComponent, filterFn: containsEitherMultipleTagFilterFn, enableFilterMatchHighlighting: false, Cell: tagCell },

];

const ListViewDrawer = ({
  initMapViewChangeDetail,
  compRefClose,
  compRef,
}: Props) => {

  //List view Hooks
  const [data, setData] = useStorage(
    "listViewDrawer_elasticData",
    [],
    (data: object[]) => {
      const maxLimit = 500;
      const localStorageSizeMB = JSON.stringify(localStorage).length / 1048576;
      const dataSizeMB = JSON.stringify(data).length / 1048576;
      console.debug(
        `visops-list-view: listViewDrawer_elasticData caching,
        Data length: ${data.length
        }, current localStorageSize: ${localStorageSizeMB.toFixed(
          2
        )} MB, new data size: ${dataSizeMB.toFixed(
          2
        )} MB, saving only ${maxLimit} records`
      );
      return table.getRowModel().rows.map((row) => row.original).slice(0, maxLimit);
    }
  );
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [columnSizing, setColumnSizing] = useLocalStorage("listViewDrawer_columnSizing", []);
  const [columnVisibility, setColumnVisibility] = useLocalStorage("listViewDrawer_columnVisibility", []);
  const [columnOrder, setColumnOrder] = useLocalStorage("listViewDrawer_columnOrder", []);
  const [columnFilters, setColumnFilters] = useLocalStorage("listViewDrawer_columnFilters", []);
  const [globalFilter, setGlobalFilter] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listViewError, setListViewError] = useState([]);
  const [onGoingBackendLoadIndicator, setOnGoingBackendLoadIndicator] = useState<boolean>(true);
  const columns = useMemo(() => columnDef, []);

  function triggerMarketMoveActionCard(selectionData: [], selectedCount: number) {
    compRef.openMarketMoveWithSelectedDataThroughListView(selectionData, selectedCount);
  }

  useEffect(() => {
    const initialDataSize = Array.from(data).length
    if (initialDataSize > 0 || listViewError.length > 0) {
      setIsLoading(false);
    }
  }, [data]);

  function responseHasError(response: ListViewApiResponseObject) {
    console.log("visops-list-view: ", response.elasticApiErrorMessage)
    setListViewError(response.elasticApiErrorMessage);
    return response.elasticApiErrorMessage.length === 0 ? false : true;
  }

  const ErrorAlert = () => {
    const email = 'DIST-Fuels-Pricing-ProdSuppSWAT@7-11.com';
    const alertMessage = 'Please reopen listview. If issue persists, contact support.';
    const [linkMessage, setLinkMessage] = useState(`Click to copy support email`);
    return (<div style={{ width: '100%' }}>
      <Collapse in={listViewError.length === 0 ? false : true}>
        <Alert
          severity="error"
          action={
            <Tooltip title="Close Error message">
              <IconButton
                color="error"
                size="small"
                onClick={() => {
                  setListViewError([]);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          }>
          <AlertTitle>Unexpected Error in Listview</AlertTitle>
          <ul>
            {
              listViewError.map((message, key) => (
                <li key={key}> {message} </li>
              ))
            }
          </ul>
          {alertMessage}
          <Tooltip title={linkMessage}>
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                navigator.clipboard.writeText(email).then(
                  () => {
                    setLinkMessage("Copied to clipboard!");
                    setTimeout(() => setLinkMessage(""), 2000);
                  },
                  (err) => {
                    console.error("Could not copy text: ", err);
                  }
                );
              }}
            >
              <MailOutlineIcon />
            </IconButton>
          </Tooltip>
        </Alert>
      </Collapse>
    </div>
    );
  }

  useEffect(() => {
    fetchListViewDataFromElasticSearch().then((res) => {
      const listviewDataArray = res.elasticSourceData;
      if (!responseHasError(res)) {
        // Apply the same transformation to all fields
        try {
          listviewDataArray.forEach((row) => {
            columns.forEach((column) => {
              const accessorKey = column.accessorKey;
              if (accessorKey) {
                let rowDataInString = row[accessorKey] ? row[accessorKey] : "";
                row[accessorKey] = rowDataInString;
              }
            });
          });
        } catch (e) {
          console.error("visops-list-view: Error in transforming data", e);
        }
      }
      setOnGoingBackendLoadIndicator(false);
      setData(listviewDataArray);
    });
  }, [columns]);

  useEffect(() => {
    console.debug("visops-list-view: rowSelection", rowSelection);
  }, [rowSelection]);

  const exportAsCSV = (table: MRT_TableInstance<any>, exportType: string) => {
    let fileNameSuffix = Date.now();
    const headersAccessorKeyMap = new Map();

    table.getVisibleFlatColumns().map(col => headersAccessorKeyMap.set(col.columnDef.id, col.columnDef.header));
    const rowData = exportType.includes(SELECTED) ? table.getSelectedRowModel().rows : table.getFilteredRowModel().rows;
    const dataToConvert = {
      data: stringifyNestedObjects(rowData.map((row) => row.original), headersAccessorKeyMap),
      filename: exportType + STORE_EXPORT + fileNameSuffix + '.csv',
      delimiter: DELIMITER,
      // headers: Array.from(String(table._getColumnDefs().map(col=> col.header)))
    };
    console.debug("visops-list-view: converted data", dataToConvert);
    csvDownload(dataToConvert);
  };

  function locateOnMap(storeDetails) {
    compRef.locateOnMap?.({
      ...storeDetails,
      showInfoWindow: false,
      isNewKPIPin: true,
    });
  };

  function resetMap() {
    compRef.setMapToinitialState();
  }

  const handleRowSelection = ({ row, staticRowIndex, table }) => {
    return (event) => {
      console.debug("visops-list-view: handleRowSelection - row", row.original, staticRowIndex);
      console.debug("visops-list-view: handleRowSelection - rowSelection", rowSelection);

      const isModifierKeyPressed = event.shiftKey || event.ctrlKey || event.metaKey;
      const isSomeRowsSelected = table.getIsSomeRowsSelected();
      const isRowSelected = table.getSelectedRowModel().rowsById.hasOwnProperty(row.id);
      const isNoRowsSelected = !isSomeRowsSelected;
      const isAllRowSelected = table.getIsAllRowsSelected();

      if (isAllRowSelected && !isModifierKeyPressed) {
        locateOnMap(row.original);
      } else {
        if (isSomeRowsSelected && isModifierKeyPressed) {
          getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event);
          resetMap();
        }
        if (isNoRowsSelected) {
          getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event);
          locateOnMap(row.original);
        }
        if (isRowSelected) {
          locateOnMap(row.original);
        }
        if (isSomeRowsSelected && !isModifierKeyPressed && !isRowSelected) {
          table.resetRowSelection();
          getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event);
          locateOnMap(row.original);
        }
      }
    };
  };

  //CSS Configurations
  const muiTableBodyPropsCSSSettings = {
    sx: {
      '& tr:nth-of-type(even) > td': {
        backgroundColor: '#fff',
      },
      '& tr:nth-of-type(odd) > td': {
        backgroundColor: '#f5f5f5',
      },
    },
  };
  const displayColumnDefOptions = {
    'mrt-row-select': {
      size: 32,
      minSize: 20,
    },
    'mrt-row-numbers': {
      size: 32,
      minSize: 20,
    },
  }
  const muiTableContainerCSSSettings = ({ table }) => ({ sx: { maxHeight: table.getState().isFullScreen ? 'calc(100vh - 106px) !important' : 'calc(100vh - 186px)', minHeight: table.getState().isFullScreen ? 'calc(100vh - 106px) !important' : 'calc(100vh - 186px)' } });
  const theme = createTheme({
    palette: {
      primary: {
        main: "hsl(120,69%,28%)",
      },
      secondary: {
        main: "hsl(208, 100%, 40%)"
      },
      info: {
        main: "hsl(210, 10%, 50%)"
      }
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '1.1rem', // Adjust font size for table cells
          },
          head: {
            color: 'hsl(120,69%,28%)',
            fontWeight: 'bold', // Optionally make header text bold
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '0.8rem', //override to make tooltip font size larger
          },
        },
      },
    },
  });

  //All html component renderers
  const renderCustomTopToolbar = (props: { table: MRT_TableInstance<any> }) => {

    const selectedRowCount = table.getSelectedRowModel().rows.length;
    const totalRowCount = table.getRowCount();
    const isTableFiltered = table.getState().columnFilters.length > 0;
    const isColumnAllVisible = table._getColumnDefs().length === table.getVisibleFlatColumns().length
    const totalRowCountDisplay = isLoading ? '' : totalRowCount ? totalRowCount : "0";
    const selectedRowCountDisplay = isLoading ? '' : selectedRowCount ? selectedRowCount : "0";
    const totalStoreToolTip = `${totalRowCount} Total Store(s) Found`;
    const clearSelectedStoreToolTip = `Clear ${selectedRowCount} Selected Row(s)`;
    const exportIsDisabled = isLoading || table.getFilteredRowModel().rows.length === 0;
    let exportType = exportIsDisabled ? DISBALED : selectedRowCount === 0 ? isTableFiltered ? FILTERED : ALL : SELECTED;
    exportType = isColumnAllVisible ? exportType : VISIBLE_COLUMNS + ' ' + exportType;
    const gasStationIcon = <LocalGasStationIcon />;
    return (
      <div className="custom-toolbar">
        <div className="top-left-custom-toolbar">
          <Tooltip title={totalStoreToolTip}>
            <span>
              <Badge
                badgeContent={totalRowCountDisplay}
                color={onGoingBackendLoadIndicator ? "info" : "primary"}
                max={100000}
              >
                <IconButton
                  color={onGoingBackendLoadIndicator ? "info" : "primary"}
                >
                  {gasStationIcon}
                </IconButton>
              </Badge>
            </span>
          </Tooltip>
          <Tooltip title={clearSelectedStoreToolTip}>
            <span>
              <Badge
                badgeContent={selectedRowCountDisplay}
                color={"secondary"}
                max={100000}
              >
                <IconButton
                  color="secondary"
                  onClick={() => {
                    table.resetRowSelection();
                  }}
                >
                  {gasStationIcon}
                </IconButton>
              </Badge>
            </span>
          </Tooltip>
          <Tooltip title={`Export CSV : ${exportType} `}>
            <span>
              <IconButton
                color="primary"
                onClick={() => { exportAsCSV(table, exportType) }}
                disabled={exportIsDisabled}
              >
                <FileDownloadIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Clear Filters">
            <span>
              <IconButton
                color="warning"
                disabled={
                  !table.getState().columnFilters.length &&
                  !table.getState().globalFilter
                }
                onClick={() => {
                  table.resetColumnFilters();
                  table.resetGlobalFilter();
                }}
              >
                <FilterListOffIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
        <div className="custom-toolbar top-right-custom-toolbar">
          <div> <MRT_GlobalFilterTextField table={table} /> </div>
          <div>
            <MRT_ToggleGlobalFilterButton table={table} />
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </div>
          <div className="list-view-close-button">
            <Tooltip title={"Close the panel"}>
              <IconButton
                className="list-view-cross-icon"
                onClick={compRefClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
  function thresholdRowSelectionAlert(totalSelectedStoresCount: number) {
    if (totalSelectedStoresCount >= 500) {
      alert('WARN: Selected more than 500 stores');
    }
  }

  //Context menu
  const renderCellActionMenuItems = ({ closeMenu, table }) => {
    if (table.getSelectedRowModel().rows.length > 0) {
      const totalSelectedStores = table.getSelectedRowModel().rows.length;
      // const selectedData = stringifyNestedObjects(table.getSelectedRowModel().rows).map((row) => row.original);
      const selectedDataJson = (table.getSelectedRowModel().rows).map((row) => row.original.importcode);
      return ([
        <MRT_ActionMenuItem
          icon={<Storefront />}
          key={0}
          label={`Selected Stores ${totalSelectedStores}`}
          sx={{ color: 'hsl(120,69%,28%)', fontWeight: 'bold', pointerEvents: 'none', fontStyle: 'italic', }}
          onClick={() => {
            thresholdRowSelectionAlert(totalSelectedStores);
            closeMenu();
          }}
          table={table}
        />,
        <MRT_ActionMenuItem
          icon={<Insights />}
          key={1}
          label="Market Move"
          onClick={async () => {
            thresholdRowSelectionAlert(totalSelectedStores);
            // const listViewSelectionActionPerformed = new CustomEvent('listViewSelectionMarketMove', selectedData);
            // window.dispatchEvent(listViewSelectionActionPerformed);
            console.debug("visops-list-view: Market Move triggered TotalSelected=", totalSelectedStores, " selectedDataJson=", selectedDataJson);
            triggerMarketMoveActionCard(selectedDataJson, totalSelectedStores);
            closeMenu();
          }}
          table={table}
        />,
        <MRT_ActionMenuItem
          icon={<AppRegistration />}
          key={3}
          label="Edit Strategy"
          onClick={async () => {
            console.debug("visops-list-view: Edit Strategy triggered TotalSelected=", totalSelectedStores, " selectedDataJson=", selectedDataJson);
            closeMenu();
          }}
          table={table}
        />
      ]);
    }
  };

  //Material table properties
  const table = useMaterialReactTable({
    columns,
    data,
    muiSkeletonProps: {
      animation: 'pulse',
    },
    filterFns: {
      multipleOrFn,
      containsEitherMultipleTagFilterFn,
      containsAllMultipleTagFilterFn,
      multiSelectOrFilterFn
    },
    localization: {
      filterMultipleOrFn: 'Multiple',
      filterContainsEitherMultipleTagFilterFn: 'Auto Complete Either Tag',
      filterContainsAllMultipleTagFilterFn: 'Auto Complete All Tag',
      filterMultiSelectOrFilterFn: 'Auto Complete Multi',
    } as any,
    enableColumnPinning: true,
    enablePagination: false,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    enableRowVirtualization: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableFacetedValues: true,
    enableColumnFilterModes: true,
    enableMultiSort: true,
    enableRowNumbers: false,
    enableColumnResizing: true,
    getRowId: (originalRow) => originalRow.importcode,
    onRowSelectionChange: setRowSelection,
    onColumnSizingChange: setColumnSizing,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      pagination: { pageSize: 0, pageIndex: 0 },
      isLoading: isLoading,
      rowSelection,
      columnSizing,
      columnVisibility,
      columnOrder,
      columnFilters,
      globalFilter,
    },
    memoMode: 'cells',
    enableCellActions: true,
    positionToolbarAlertBanner: 'none',
    renderCellActionMenuItems: renderCellActionMenuItems,
    renderTopToolbar: renderCustomTopToolbar,
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
      sx: {
        maxWidth: '180px'
      },
    },
    initialState: {
      density: "compact",
      showColumnFilters: true,
      // columnPinning: {
      //   left: ['mrt-row-numbers', 'mrt-row-select'],
      // },
    },
    muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      onClick: handleRowSelection({ row, staticRowIndex, table }),
      sx: { cursor: "pointer", height: "18px" },
    }),
    mrtTheme: (theme) => ({ baseBackgroundColor: 'hsl(120, 40%, 96%)', menuBackgroundColor: 'hsl(0, 0%, 96%)', selectedRowBackgroundColor: 'hsl(150,50%,69%)', }),

    displayColumnDefOptions: displayColumnDefOptions,
    muiFilterTextFieldProps: { SelectProps: { MenuProps: { sx: { maxHeight: '400px' }, } } },
    muiTableHeadCellProps: { sx: (theme) => ({ backgroundColor: 'hsl(120, 40%, 96%)', }) },
    muiTableContainerProps: muiTableContainerCSSSettings,
    muiTableBodyProps: muiTableBodyPropsCSSSettings,
  });

  return (
    <ThemeProvider theme={theme}>
      <ErrorAlert />
      <div className="list-view-scroll-pane non-selectable ">
        <MaterialReactTable table={table} />
      </div>
    </ThemeProvider>
  );
};

export default ListViewDrawer;

function multiAutoCompleteFilterComponent(props: {
  column: MRT_Column<any, unknown>;
  header: MRT_Header<any>;
  rangeFilterIndex?: number;
  table: MRT_TableInstance<any>;
}): React.ReactNode {
  const { column, table } = props;
  const columnAccessorKey = column.columnDef.accessorKey;
  const allOptions: Set<string> = new Set();
  const tableStateSelectedFilters: Set<unknown> = new Set();
  const filterByLabel = `Filter by ${column.columnDef.header}`;
  //Table state selected column filters
  table.getState().columnFilters.map((entry) => {
    if (entry.id === columnAccessorKey) {
      entry.value.toString().split(DELIMITER).forEach((item) => tableStateSelectedFilters.add(item));
    }
  });
  //All Faceted Options from Column
  column._getFacetedUniqueValues().forEach((key, value) => {
    if (columnAccessorKey.includes('Tag')) {
      value?.toString().split(DELIMITER).forEach((element) => {
        element.length > 0 ? allOptions.add(String(element).trim()) : '';
      })
    } else {
      value && value.length !== 0 ? allOptions.add(String(value).trim()) : allOptions.add(String(EMPTY));
    }
  }
  );

  return (
    <Autocomplete
      multiple
      disablePortal
      limitTags={1}
      className="auto-complete-filters"
      size="medium"
      options={Array.from(allOptions).sort()}
      value={Array.from(tableStateSelectedFilters)}
      onChange={(event, value) => {
        const stringSearch = value.toString().replace('[', '').replace(']', '');
        console.debug("visops-list-view: stringSearch", stringSearch, "event");
        column.setFilterValue(stringSearch);
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return <Chip
            icon={columnAccessorKey.includes('Tag') ? <i className="fas fa-tag" style={{ fontSize: '0.8rem', paddingLeft: '1px', }} /> : <></>}
            style={{ height: '18px' }}
            variant="outlined"
            label={option}
            size="small"
            color="secondary"
            {...getTagProps({ index })}
          />
        })
      }
      renderOption={(props, option, { selected }) =>
        <li {...props}>
          <Checkbox
            style={{ marginLeft: -10 }}
            checked={selected}
          />
          {option}
        </li>
      }
      style={{ minWidth: 160 }}
      renderInput={(params) => <TextField {...params} variant="standard" placeholder={filterByLabel} FormHelperTextProps={{ sx: { margin: '0 0 3px' } }} helperText='Filter Mode: Auto Complete' />}
    />
  );
}
