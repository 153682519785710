import axios, { AxiosError, AxiosResponse, CancelToken } from "axios";
import { debounce } from "lodash";
import constant from "../utils/constants/constant.json";
import { constructESHeader, formServiceUrl } from "./apiUrlUtil";
import { QueryBuilder } from "./elasticQueryBuilder";
import { flattenPricePush } from "./utils";

debounce(function (callback) {
  return callback();
}, 300);

export const GEOLOCATION = constant.urlConstants.elasticsearch.geolocationSearch;
export const STORE_ATTRIBUTES = constant.urlConstants.elasticsearch.storeattributesSearch;

interface ElasticSearchResponseObject {
  hits: {
    hits: {
      sort: [],
      _id: String,
      _index: String,
      _score: any,
      _source: any,
      _type: String
    }[],
    total: {
      value: number,
      relation: String,
    }
  }
}

export interface ListViewApiResponseObject {
  elasticSourceData: any[],
  elasticApiErrorMessage: any[]
}

export async function fetchListViewDataFromElasticSearch(): Promise<ListViewApiResponseObject> {
  const geolocationResponse = await fetchMoreThanThresholdDataFromElasticSearch(GEOLOCATION);
  const storeattributesResponse = await fetchMoreThanThresholdDataFromElasticSearch(STORE_ATTRIBUTES);
  const listViewImportCodeMap = mergeGelocationAndStoreAttributes(geolocationResponse, storeattributesResponse);
  const finalListViewArray = Array.from(listViewImportCodeMap.values());

  let result:ListViewApiResponseObject = {elasticSourceData : [], elasticApiErrorMessage: []};
  geolocationResponse.elasticApiErrorMessage.length === 0 ? '': result.elasticApiErrorMessage.push(geolocationResponse.elasticApiErrorMessage);
  storeattributesResponse.elasticApiErrorMessage.length === 0 ? '' : result.elasticApiErrorMessage.push(storeattributesResponse.elasticApiErrorMessage);
  result.elasticSourceData = finalListViewArray;

  console.debug("visops-list-view: final listview data", finalListViewArray);
  console.log("visops-list-view: final listview size", finalListViewArray.length);
  return result;
}

export async function fetchMoreThanThresholdDataFromElasticSearch(documentName: string): Promise<ListViewApiResponseObject> {
  let batchSize = 8000;
  let elasticDataResponse: ListViewApiResponseObject = {elasticSourceData : [], elasticApiErrorMessage: []};
  let lastArrayIndex: number = 0;
  let searchAfterIndex: String = "";

  do {
    let axiosSource = axios.CancelToken.source();
    let response: AxiosResponse<ElasticSearchResponseObject, any>;
    try {
      response = await _fetchStoresFromElasticSearchAfter(searchAfterIndex, batchSize, documentName, axiosSource.token);
    } catch (error) {
      console.error("visops-list-view: Elastic Search Axios call failed ", error);
      let errorMessage = `${error.message}: Failed to fetch ${documentName.replace('/', '').replace('/', '').replace('_search', '')} results into listview `;
      elasticDataResponse.elasticApiErrorMessage.push(errorMessage);
    }
    if (response) {
      console.debug("visops-list-view: fetch from", documentName.replace('/', '').replace('/', '').replace('_', ' '), "elastic search response", response);
      lastArrayIndex = response.data.hits.hits.length - 1;
      searchAfterIndex = response.data.hits.hits[lastArrayIndex]._id;
      console.debug("visops-list-view: ", documentName, " batch size ", lastArrayIndex + 1, "searchAfterIndex", searchAfterIndex);
      fillDataIntoSourceArray(response.data, elasticDataResponse.elasticSourceData);
    } else {
      return elasticDataResponse;
    }
  } while (lastArrayIndex + 1 >= batchSize)
  console.log("visops-list-view:", documentName.replace('/', '').replace('/', '').replace('_', ' '), "totalSize", elasticDataResponse.elasticSourceData.length);
  return elasticDataResponse;
}

async function _fetchStoresFromElasticSearchAfter(searchAfterIndex: String, batchSize: number, documentName: string,
  cancelToken?: CancelToken): Promise<AxiosResponse<ElasticSearchResponseObject, any>> {
  const elasticSearchQuery = getQuery(documentName, searchAfterIndex, batchSize);
  return await axios.post(
    await formServiceUrl(
      constant.urlConstants.elasticsearch.name,
      documentName
    ),
    elasticSearchQuery,
    {
      cancelToken: cancelToken,
      headers: await constructESHeader()
    }
  );
}

function getQuery(documentName: string, searchAfterIndex: String, batchSize: number) {
  const queryBuilder = new QueryBuilder();
  switch (documentName) {
    case GEOLOCATION:
      return queryBuilder.queryGeolocationListViewWithSearchAfterQuery(searchAfterIndex, batchSize);
    case STORE_ATTRIBUTES:
      return queryBuilder.queryStoreAttributesWithSearchAfterQuery(searchAfterIndex, batchSize);
  }
}

function fillDataIntoSourceArray(elasticHitsObject: ElasticSearchResponseObject, storeDetailList) {
  elasticHitsObject.hits.hits.map((hit) => hit._source).forEach(storeDetail => {
    storeDetailList.push(storeDetail);
  });
  return storeDetailList;
}

function mergeGelocationAndStoreAttributes(geolocationResponse: ListViewApiResponseObject, storeattributesResponse: ListViewApiResponseObject) {
  const geolocation = geolocationResponse.elasticSourceData.map(flattenPricePush);
  const storeattributes = storeattributesResponse.elasticSourceData;

  const geolocationImportCodeMap = new Map(geolocation.map(source => [source.importcode, source]));
  const storeattributesStoreIdMap = new Map(storeattributes.map(source => [source.storeId, source]));

  geolocationImportCodeMap.forEach((geolocation, importcode) => {
    geolocation.marketMoveTag = storeattributesStoreIdMap.get(importcode)?.marketMoveTag;
    geolocation.strategyTestTag = storeattributesStoreIdMap.get(importcode)?.strategyTestTag;
    geolocation.ownSiteTag = storeattributesStoreIdMap.get(importcode)?.ownSiteTag;
  });
  console.log("visops-list-view: geolocation",geolocationImportCodeMap.size, "and storeattributes",storeattributesStoreIdMap.size,"data merged ");
  return geolocationImportCodeMap;
}

