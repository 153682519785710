export const stringifyNestedObjects = (rowData, headersAccessorKeyMap) => {
  return rowData.map(obj => {
    const transformedObj = {};
    for (let key in obj) {
      if ( headersAccessorKeyMap.has(key) && obj.hasOwnProperty(key)) {
        const value = key.includes('Tag') ? String(obj[key]).split(",").map(tags=>tags.trim()).toString().replace('[','').replace(']','').trim() : String(obj[key]).trim();
        key = headersAccessorKeyMap.get(key);
        if (typeof value === 'object' && value !== null) {
          transformedObj[key] = JSON.stringify(value);
        } else {
          transformedObj[key] = value;
        }
      }
    }
    return transformedObj;
  });
};
export const flattenPricePush = (data) => {
  const {
    pricePush: pricePush,
    pricePush: {
      regular: { price: regPrice = null, effectiveDate: regPriceValidFrom = null } = {},
      diesel: { price: dslPrice = null, effectiveDate: dslPriceValidFrom = null } = {},
    } = {},
    ...rest
  } = data;

  return {
    ...rest,
    pricePush,
    regPrice,
    regPriceValidFrom,
    dslPrice,
    dslPriceValidFrom,
  };
};
